import React from 'react';
import utils from '../utils/utils';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const NotificationIcon = (props) => {
  const { unreadNotificationsCounter } = props;
  const location = useLocation();

  return (
    <div className={"iconContainer " + (utils.isUserInUrl(location, "notifications") ? "active" : "")}>
      <Link to="/notifications" id="gtm-navbar-mobile-notifications">
        {" "}
        <span className="navMobile-notificationsCounter" style={{ display: unreadNotificationsCounter > 0 ? 'block' : 'none' }}>
          {unreadNotificationsCounter > 99 ? ('+99') : (unreadNotificationsCounter) }
        </span>
        <FontAwesomeIcon icon={faBell} className="navMobile-notificationsButton" />
      </Link>
    </div>
  )
}

const mapStateToProps = state => ({
  unreadNotificationsCounter: state.unreadNotificationsCounter
})

export default connect(mapStateToProps)(NotificationIcon);
