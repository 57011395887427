import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Animate from "react-smooth/lib/Animate";
import styled from "styled-components";
import AccessWallComponent from "../components/accessWallComponent";
import NavbarMobileComponent from "../components/navbarMobileComponent";
import NpsComponent from "../components/npsComponent";
import SearchBarComponent from "../components/searchBarComponent";
import ToastExpiringPremium from "../components/toastExpiringPremium";
import auth from "../modules/auth";
import notificationsAPI from "../modules/notificationsAPI";
import notificationsReducer from "../reducers/notificationsReducer";
import constant from "../utils/constant";
import utils from "../utils/utils";
import DocumentModalComponent from "./documentModalComponent";
import FreeWallPremium from "./freeWallPremium";
import LoggedOutModalComponent from "./loggedOutModalComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import ToastPaymentError from "./toastPaymentError";
import LineupSalePopUp from "./lineupSalePopUp";

const NavbarComponent = (props) => {
  const { status, user, unreadNotificationsCounter } = props;
  const { t } = useTranslation();
  let history = useHistory();
  const [isWallOpen, setIsWallOpen] = useState(false);
  const isAppleApp = utils.isAppleDomain() || utils.isPremiumAppleDomain();
  
  // Notifications
  useEffect(() => {
    async function getUnreadNotifications() {
      let notificationListResult = await notificationsAPI.getUnread();
      notificationsReducer.updateNotifications(notificationListResult.length);
    }
    if (status === "logged" && unreadNotificationsCounter === undefined) {
      getUnreadNotifications();
    }
  }, []);

  const logOut = async () => {
    await auth.logout();
    window.location.href = "/";
  };

  const goToLink = (path) => {
    history.push({
      pathname: path,
      search: "",
      state: { status: status, user: user },
    });
  };

  const goToPremium = () => {
    let path = "/access";
    if (user && user.isPremium !== undefined) {
      if (user.isPremium === true) {
        path = "/favorites/list";
      } else {
        setIsWallOpen(true);
        return;
      }
    }
    history.push({
      pathname: path,
      search: "",
      state: { status: status, user: user },
    });
  };

  const logosvg = require('../assets/logo/logo-profile.svg');

  const profileImageUrl = props.user?.profileImageUrl == "" ? logosvg : props.user.profileImageUrl;

  return (
    <>
      <>
        <NpsComponent />
        <DocumentModalComponent needToShow={true} />
        <LoggedOutModalComponent needToShow={true} />
        <FreeWallPremium />
        <LineupSalePopUp />
        <ToastExpiringPremium textName={"profile_header_active_account"} backgroundColor="#e6e6e6" marginTop="0px" marginBottom="0px" fontSize="0.85em" />
        <ToastPaymentError textName={"profile_header_active_account"} backgroundColor="#cc1a2a" marginTop="0px" marginBottom="0px" fontSize="0.85em"/>
        <AccessWallComponent openModal={isWallOpen} setIsWallOpen={setIsWallOpen} feature="multispot" user={user} />
        <NavbarMobileComponent goToPremium={goToPremium} unreadNotificationsCounter={unreadNotificationsCounter} />
        <Animate to="1" from="0" duration={500} attributeName="opacity">
          <NavbarContainer>
            <header className="header-default">
              {/*Desktop */}
              <nav className="navbar navbar-expand-lg">
                <div className="container-xl">
                  {/* site logo */}
                  <a className="navbar-brand" id="gtm-navbar-desktop-logo" onClick={() => goToLink('/')}>
                  {/* <Link className="navbar-brand" to="/" id="gtm-navbar-desktop-logo"> */}
                    <img crossOrigin="anonymous" src={require("../assets/logo/logo.svg")} alt="logo" width="600" height="400" />
                  {/* </Link> */}
                  </a>
                  <div className="collapse navbar-collapse">
                    {/* menus */}
                    <ul className="navbar-nav mr-auto">
                  
                      <li className="nav-item">
                        <a className="nav-link" onClick={() => goToLink(utils.getGenericSpotLink(constant.AVAILABLE_COUNTRIES[user.country]), false)} id="gtm-navbar-desktop-spots">
                          {t("nav_spots")}
                        </a>
                      </li>
                      {status === "logged" && (
                        <li className="nav-item">
                          <Link className="nav-link" to="/favorites" id="gtm-navbar-desktop-multispot">
                            {t("nav_multispot_button")}
                          </Link>
                        </li>
                      )}
                      {(!user || (user && user.isPremium !== true)) && (
                        <li className="nav-item">
                          <Link className="nav-link" to={isAppleApp ? "/access" : "/premium"} id="gtm-navbar-desktop-plans">
                            {t("nav_plans_button")}
                          </Link>
                        </li>
                      )}
                      { (props.country === constant["ABBREVIATIONS"]["Uruguay"] || user.country === constant["ABBREVIATIONS"]["Uruguay"]) &&
                        <li className="nav-item" style={{position: "relative"}}>
                          <span className="circular-point"></span>
                          <a className="nav-link" href="https://shop.lineup.surf" id="gtm-navbar-desktop-shop">
                            {t("nav_shop_button")}
                          </a>
                        </li>
                      }
                      <li className="nav-item">
                        <a className="nav-link" href="https://noticias.lineup.surf" id="gtm-navbar-desktop-news">
                          {t("nav_news_button")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <SearchBarComponent />
                      </li>
                    </ul>
                  </div>
                  {/* header right section */}
                  <div className="header-right">
                    {/* social icons */}
                    <div className="collapse navbar-collapse">
                      {/* menus */}
                      <ul className="navbar-nav mr-auto">
                        {status !== "logged" && (
                          <>
                             <li className="nav-item register-navbar">
                              <Link className="nav-link" to={isAppleApp ? "/access" : "/premium"} id="gtm-navbar-desktop-register">
                                {t("nav_register_button")}
                              </Link>
                            </li>
                            <li className="nav-item login-navbar">
                              <Link className="nav-link" to={{ pathname: "/access", state: { prevPath: window.location.pathname, isRegister: false } }} id="gtm-navbar-desktop-login">
                                {t("nav_login_button")}
                              </Link>
                            </li>
                          </>
                        )}
                        {status === "logged" && (
                          <>
                            {/* Notifications (desktop) */}
                            <li className="nav-item notifications-navbar">
                              <a className="nav-link navbar-notificationsButton" onClick={() => goToLink("/notifications")}>
                                <span className="navbar-notificationsCounter" style={{ display: unreadNotificationsCounter > 0 ? 'block' : 'none' }}>
                                  { unreadNotificationsCounter > 99 ? ('+99') : (unreadNotificationsCounter) }
                                </span>
                                <FontAwesomeIcon icon={faBell} />
                              </a>
                            </li>

                            {" "}
                            <li className="nav-item dropdown">

                              <a className="nav-link dropdown-toggle avatar" onClick={() => goToLink("/account", false)} id="gtm-navbar-desktop-profile-icon">
                                <div className="avatar-icon" style={{ backgroundImage: "url('" + profileImageUrl + "')" }} alt="Avatar" />
                              </a>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => goToLink(user.status === constant.VERIFICATION_ACCOUNT_STATUS.PENDING_ACTIVATION ? "/account/preferences" : "/account", false)}
                                    id="gtm-navbar-desktop-account">
                                    {t("nav_user_dropdown_profile_button")}
                                  </a>
                                  <a className="dropdown-item" onClick={() => goToLink("/account/wave-alerts", false)} id="gtm-navbar-desktop-account">
                                    {t("nav_user_dropdown_waves_alert_button")}
                                  </a>
                                  <a className="dropdown-item" onClick={() => goToLink("/account/referrals", false)} id="gtm-navbar-desktop-account">
                                    {t("nav_user_dropdown_referrals_button")}
                                  </a>
                                  <a className="dropdown-item" onClick={() => goToLink("/account/benefits", false)} id="gtm-navbar-desktop-account">
                                    {t("nav_user_dropdown_benefit_button")}
                                  </a>
                                  <a className="dropdown-item" onClick={() => logOut()} id="gtm-navbar-desktop-logout">
                                    {t("nav_user_dropdown_logout_button")}
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </header>
          </NavbarContainer>
        </Animate>
      </>
    </>
  );
};

const NavbarContainer = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }

  .register-navbar a {
    background-color: rgb(255, 165, 60);
    color: #fff !important;
    border-radius: 3px;
    padding: 8px 15px;
    font-weight: 500 !important;
    width: 140px;
    text-align: center;
    margin-right: 20px;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  unreadNotificationsCounter: state.unreadNotificationsCounter,
  country: state.country,
});

  export default connect(mapStateToProps)(NavbarComponent);
