import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from "react-i18next";
import utils from "../utils/utils";
import { Link } from 'react-router-dom';

const ChartComponentDesktop = (props) => {
    const { windData, premium, spotName } = props
    const [max, setmax] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        let tmp = 0;
        windData.map(({maxWindSpeed}, index) => {
            tmp = Math.max(tmp, maxWindSpeed);
        });
        setmax(tmp);
    }, []);

    return (
        <>
            <ChartTitle>{t("wind")}</ChartTitle>
            <ChartContainer premium={premium || windData.length <= 3}>
                {!premium && windData.length > 3 &&
                    <PremiumAdd>
                        <h1>{t("Pronósticos exclusivos para Premium")}</h1>
                        <p>
                            <Trans
                                i18nKey="forecast_limited_modal_description" // optional -> fallbacks to defaults if not provided
                                values={{ spotName }}
                                components={{ italic: <i />, bold: <strong /> }}
                            />
                        </p>
                        <div className='premium-button-box'>
                            <Link to={{ pathname: "/premium", state: { prevPath: window.location.pathname, isRegister: true } }}>{t("forecaster_prono_premium_alert_button")}</Link>
                        </div>
                    </PremiumAdd>
                }
            {windData.map(({day, date, maxWindSpeed , minWindSpeed, windSpeed, windOrientation, windType, needBlur}, index) => {
                return (
                    <DayContainer key={crypto.randomUUID()} className={needBlur ? "blur" : ""}>
                        <DataContainer>
                            <Day content={day} mobileContent={day.slice(0, 3)} />
                            <Date>{date}</Date>
                        </DataContainer> 
                        <Set>
                            <ArrowContainer>
                                {windOrientation.map((orientation, index) => {
                                    return <Arrow key={crypto.randomUUID()} scale={(windSpeed[index] / max) * 100}> <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${orientation}`} /> </Arrow>
                                })}
                            </ArrowContainer>
                            <QualityContainer>
                                {windType.map((type, index) => {
                                    return <Quality key={crypto.randomUUID()} className={`wind-${utils.formatWind(type)}`}/>
                                })
                                }
                            </QualityContainer>
                            <MaxWind>{maxWindSpeed + " km/h"}</MaxWind>
                            <MinWind>{minWindSpeed} <span>km/h</span></MinWind>
                            
                        </Set>
                    </DayContainer>
                )
            })}
            </ChartContainer>
        </>
    );
}

const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    > div:nth-child(${props => props.premium ? 1 : 2}){
        border: none;
    }
    position: relative;
`

const DayContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid #e4e7ed;
`

const DataContainer = styled.div`
    width: 100%;
    height: 100px;
    dispplay: flex;
    felx-direction: row;
    align-items: center;

    @media(max-width: 1000px){
        height: auto;
    }
`

const Day = styled.p`
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    margin: 0.5rem;

    &:before {
        content: "${props => props.content}";
    }

    @media(max-width: 1000px){
        &:before{
            content: "${props => props.mobileContent}";
        }
    }
`

const Date = styled.p`
    font-weight: 500;
    color: #82808080;
    text-align: center;
    margin: 0.5rem;
`

const Set = styled.div`
    width: 100%;
    display: flex;
    position: relative;

    flex-direction: column;
`

const ArrowContainer = styled.div`
    display: flex;
    width: 100%;
`

const Arrow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        height: 1.5rem;
        scale: ${props => props.scale > 50 ? props.scale : 50}%;
        margin-bottom: 1rem;
    }

    @media(max-width: 1000px){
        min-width: 20px; // Este valor tiene que corresponderse con el que tienen las barras en la altura de olas (chart component)
        svg{
            height: 1rem;
        }
    }
`

const MaxWind = styled.p`
    font-weight: bold;
    text-align: center;
    margin: 0.5rem;
`

const MinWind = styled.p`
    font-weight: 500;
    color: #82808080;
    text-align: center;

    @media(max-width: 1000px){
        span{
            display: none;
        }
    }
`

const QualityContainer = styled.div`
    width: 100%;
    display: flex;
`

const Quality = styled.div`
    width: 100%;
    height: .5rem;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
`

const ChartTitle = styled.p`
    font-size: large;
    font-weight: bold;
    font-family: "Poppins",sans-serif;
    margin-right: 35px;
    position: sticky;
    left: 0;
`;

const PremiumAdd = styled.div`
    margin: 10px auto;
    padding: 15px 5px;
    border-radius: 5px;
    width: 70%;

    position: absolute;
    z-index: 9999;
    left: 33.3%;
    top: 50%;
    transform: translateY(-50%);

    @media(max-width: 1000px){
        left: 66.6%;
    }

    h1{
        text-align: center;
        font-size: 1.7em;
        font-weight: bold;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        color: #000;
    }

    p{
        font-size: 1.3em;
        text-align: center;
        width: 60%;
        margin: auto;
        text-shadow: 5px 5px 5px white;
    }

    a{
        background-color: #32c943;
        border-radius: 5px;
        color: #fff;
        margin: auto;
        padding: 8px 25px !important;
        font-size: 0.9em;
    }

    div{
        margin: 10px auto 0 auto;
        width: fit-content;
    }


    @media(max-width: 999px){
        width: 100%;

        h1{
            font-size: 1.5em;
        }

        p{
            font-size: 1.1em !important;
            width: 100%;
        }
    }
`

export default React.memo(ChartComponentDesktop);
