import React from 'react';
import { useTranslation } from 'react-i18next';

const SaveTheWavesButton = () => {
  const {t} = useTranslation();

  return (
    <div className='save-the-waves-container'> 
      <a href='https://apps.apple.com/us/app/save-the-waves/id1317823560?ppid=e26a63a8-4c42-4d28-ab19-50957edb79e2' target='_blank' className='normalize-button save-the-waves-button'>
        <img src={require("../assets/icons/save-the-waves-logo-short.png")}/>
        {t("save_the_waves_report_button")}
      </a>
    </div>
  )
}

export default SaveTheWavesButton
