import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WaveQualityComponent from "./waveQualityComponent";

const ChartComponentSwell = (props) => {
    const { chartData, premium, spotName} = props;
    const [max, setMax] = useState(0);
    const [waves, setWaves] = useState([]);
    const { t } = useTranslation();

    const createSets = chartData => {
        return chartData.map(({dayHeights, ...element}, index) => {
            let labelTop = 0;
            let maxWave = 0;
            let minWave = Number.MAX_SAFE_INTEGER;
            return {
                ...element,
                dayHeights: dayHeights.map( (height, index) => {
                    const barHeight = (height / max) * 100;
                    labelTop = Math.max(labelTop, barHeight); 
                    maxWave = Math.max(maxWave, height);
                    minWave = Math.min(minWave, height);
                    return {
                        waveHeight: height,
                        barHeight,
                    }
                }),
                labelTop,
                maxWave,
                minWave,
            }
        })
    }


    useEffect(() => {
        
        let tmp = 0; 
        chartData.forEach((element, index) => {
            tmp = Math.max( tmp, element.dayHeights.reduce((accumulate, current) => Math.max(accumulate, current)) );
        });
        setMax(tmp);

    }, []);

    useEffect(() => {
        if(max > 0){
            let res = createSets(chartData);
            res[0].day = t("_TODAY");
            setWaves(res);
        }
    }, [max]);



    return (
        <>
           <ChartTitle>{ t("swell_height_1")  } </ChartTitle>
            {waves.length > 0 && (
            <ChartContainer premium={premium || chartData.length <= 3}>
                {!premium && chartData.length > 3 && 
                    <PremiumAdd>
                            <h1>{t("Pronósticos exclusivos para Premium")}</h1>
                            <p>
                                <Trans
                                    i18nKey="forecast_limited_modal_description" // optional -> fallbacks to defaults if not provided
                                    values={{ spotName }}
                                    components={{ italic: <i />, bold: <strong /> }}
                                />
                            </p>
                            <div className='premium-button-box'>
                                <Link to={{ pathname: "/premium", state: { prevPath: window.location.pathname, isRegister: true } }}>{t("forecaster_prono_premium_alert_button")}</Link>
                            </div>
                    </PremiumAdd>
                }
            {waves.map(({day, date, dayHeights, orientations, periods,ratings, orientationsQuality, labelTop, maxWave, minWave, needBlur}, indexDay) => {
                return (
                    <DayContainer key={crypto.randomUUID()} className={needBlur ? "blur" : ""}>
                        <DataContainer>
                            {/* <Day content={day} mobileContent={indexDay != 0 ? day.slice(0, 2) : day}/>
                            <Date>{date}</Date> */}
                            <ArrowContainer>
                                {orientations.map((orientation, index) => {
                                    return <Arrow key={crypto.randomUUID()}> <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${orientation}`} /> </Arrow>
                                })}
                            </ArrowContainer>
                            <QualityContainer>
                                {orientationsQuality.map((quality, index) => {
                                    return <Quality key={crypto.randomUUID()} className={`bg-swell-direction-${quality}`}/>
                                })}
                            </QualityContainer>
                            <MaxWind>{Math.max.apply(null, periods) + " s"}</MaxWind>
                            <MinWind>{Math.min.apply(null, periods) + " s"}</MinWind>               
                        </DataContainer>
                        <Set>
                            <WaveHeight top={labelTop}>
                                <p>{minWave}</p> 
                                <p>-</p>
                                <p>{maxWave + "m"}</p>
                            </WaveHeight>
                            {dayHeights.map(({barHeight, waveHeight}, indexHour) => (
                                <BarContainer key={crypto.randomUUID()}>
                                    <BarChart height={barHeight} />
                                    {indexDay == 0 && (
                                        <Hour>
                                            {
                                            indexHour == 0 ? "6am" : 
                                            indexHour == 1 ? "12pm" : "6pm"
                                            }
                                        </Hour>
                                    )}
                                    {/*  */}
                                </BarContainer>
                            ))}
                        </Set>
                    </DayContainer>
                )
            })}
            </ChartContainer>
            )}
        </>
    );
}

const DayContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid #e4e7ed;
`

const DataContainer = styled.div`
    width: 100%;
    height: 175px;
    dispplay: flex;
    felx-direction: row;
    align-items: center;
`

const Day = styled.p`
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    margin: 0.5rem;

    &:before {
        content: "${props => props.content}";
    }

    @media(max-width: 1000px){
        &:before{
            content: "${props => props.mobileContent}";
        }
    }
`

const Date = styled.p`
    font-weight: 500;
    color: #82808080;
    text-align: center;
    margin: 0.5rem;
`

const ArrowContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 1rem;
`

const RatingContainer = styled.div`
    display:flex;
    width: 100%;
    >div{width:33.3%};
`

const Arrow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        height: 1rem;
    }

    @media(max-width: 1000px){
        min-width: 20px; // Este valor tiene que corresponderse con el que tiene el Arrow del viento (chart component wind)
        svg{
            height: .8rem;
        }
    }
`

const Set = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`

const BarContainer = styled.div`
    height: 150px;
    width: 33.3%;
    display: flex;
    align-items: flex-end;
    position: relative;

    @media(max-width: 1000px){
        min-width: 20px; // Este valor tiene que corresponderse con el que tiene el Arrow del viento (chart component wind)
        height: 66px;
    }
`
const WaveHeight = styled.div`
    display: flex;
    width: auto;
    height: auto;
    font-size: 0.8rem;
    position: absolute;
    left: calc(50%);
    transform: translateX(-50%);
    top: calc( 100% - ${props => props.top}% - 35px);
    p{
        font-weight: bold;
    }
`

const BarChart = styled.div`
    background: #3ebbea;
    border-left: 2px solid white;
    border-right: 2px solid white;
    height: ${props => props.height + "%"};
    width: 100%;
`

const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    > div:nth-child(${props => props.premium ? 1 : 2}){
        border: none;
    }
    position: relative;
`

const ChartTitle = styled.p`
    font-size: large;
    font-weight: bold;
    font-family: "Poppins",sans-serif;
    margin-right: 35px;
    position: sticky;
    left: 0;
`;

const QualityContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: .5rem;
`

const Quality = styled.div`
    width: 100%;
    height: .5rem;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
`

const MaxWind = styled.p`
    font-weight: bold;
    text-align: center;
    margin: 0.5rem;
`

const MinWind = styled.p`
    font-weight: 500;
    color: #82808080;
    text-align: center;
`
const Hour = styled.p`
    font-weight: 500;
    font-size: 0.8rem;
    color: #82808080;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -20px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width: 1000px){
        display: none;
    }
`

const PremiumAdd = styled.div`
    margin: 10px auto;
    padding: 15px 5px;
    border-radius: 5px;
    width: 70%;

    position: absolute;
    z-index: 9999;
    left: 33.3%;
    top: 50%;
    transform: translateY(-50%);

    @media(max-width: 1000px){
        left: 66.6%;
    }

    h1{
        text-align: center;
        font-size: 1.7em;
        font-weight: bold;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        color: #000;
    }

    p{
        font-size: 1.3em;
        text-align: center;
        width: 60%;
        margin: auto;
    }

    a{
        background-color: #32c943;
        border-radius: 5px;
        color: #fff;
        margin: auto;
        padding: 8px 25px !important;
        font-size: 0.9em;
    }

    div{
        margin: 10px auto 0 auto;
        width: fit-content;
    }


    @media(max-width: 999px){
        width: 100%;

        h1{
            font-size: 1.5em;
        }

        p{
            font-size: 1.1em !important;
            width: 100%;
        }
    }
`


export default React.memo(ChartComponentSwell);
