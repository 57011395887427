import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import utils from "../utils/utils";
import constant from "../utils/constant";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import PopupOverlay from "./popupOverlay"; 
import { useHistory } from "react-router-dom/";

const AddComponent = (props) => {
  // const { isPremiumPlan, isRefill, refillPlan, selectedPlan: selectedPlanProp, anualPlan, monthlyPlan, freePlan, user, setIsMonthPlan, status, isAppleDevice, discount } = props;
  const {
    //both cards props
    isPremiumPlan, discount, setIsMonthPlan, selectedPlan: selectedPlanProp, camCount,
    //free card props
    freePlan,   
    //premium card props
    monthlyPlan, anualPlan,
    //redux props
    user, status, isAppleDevice,
  } = props; 
  const { t } = useTranslation();
  const [isMonth, setIsMonth] = useState(false);
  const [amountToPay, setAmountToPay] = useState("0.00");
  const [annualTotalDiscount, setAnnualTotalDiscount] = useState(0);
  const [blockedPlan, setBlockedPlan] = useState(false);
  const [currency, setCurrency] = useState("");
  const [savingMoney, setSavingMoney] = useState();
  const [openPopUpIos, setOpenPopUpIos] = useState(false);
  const history = useHistory();

  const isHundredPercentOff = discount?.amount === 100;

  const planFeatures = [
    // { premium: false, description: "plan_card_feature_cameras_comparison" },
    { premium: false, description: "plan_card_feature_forecaster_prono_comparison" },
    { premium: false, description: "plan_card_feature_forecast_comparison" },
    { premium: false, description: "plan_card_feature_favorites_comparison" },
    { premium: false, description: "plan_card_feature_wave_alert_comparison" },
    { premium: true, description: "plan_card_feature_cam_rewind_comparison" },
    { premium: true, description: "plan_card_feature_no_ads_comparison" },
    { premium: true, description: "plan_card_feature_benefits_comparison" }
  ]

  useEffect(() => {
    verifyPlan(isMonth);
  }, [isMonth]);

  useEffect(() => {
    calculateAmountToPay();
    calculateAnnualTotalDiscount();
  }, []);

  useEffect(() => handleDiscounts(), [discount] ); 

  const selectedPlan = () => {
    if(utils.isAppleDomain()){
      setOpenPopUpIos(true);
      utils.toggleScrollBar(true);
      return;
    }

    selectedPlanProp(isPremiumPlan ? (!isMonth ? anualPlan : monthlyPlan) : freePlan);
  };

  const handlePlan = (needChange) => {
    const changePlan = !isMonth;
    verifyPlan(changePlan);
    setIsMonth(changePlan);
    if (isPremiumPlan) {
      setIsMonthPlan(changePlan);
    }
  };

  const verifyPlan = (isMonthActive) => {
    if (user) {
      if (!status) { //When the user is not logged the status is undefined
        setBlockedPlan(false)
      } else if ((!user.isPremium || user.nextPlanId === constant.SUBSCRIPTION.FREE) && !isPremiumPlan) {
        setBlockedPlan(true);
      } else {
        if ((isPremiumPlan && !isMonthActive && user.nextPlanId === constant.SUBSCRIPTION.ANNUALLY) || (isMonthActive && user.nextPlanId === constant.SUBSCRIPTION.MONTHLY)) {
          setBlockedPlan(true);
        } else {
          setBlockedPlan(false);
        }
      }
    }
  };

  const calculateAmountToPay = () => {
    if (isPremiumPlan) {

      const currentPlan = isMonth ? monthlyPlan : anualPlan;
      let userDiscount = parseFloat(currentPlan.saving_money);
      let planAmount = parseFloat(currentPlan.amount) + userDiscount;

      if (discount) {
        // planAmount = utils.calculateAmountToPay(planAmount, discount);
        let couponDiscount = utils.calculatePercentage(planAmount, discount.amount);
        userDiscount += couponDiscount;
        planAmount = planAmount - userDiscount;
      }

      let annualListPrice = (monthlyPlan.amount * 12);
      let percentageOffListPrice = Math.round((userDiscount / annualListPrice) * 100);

      setSavingMoney(percentageOffListPrice);
      setCurrency(currentPlan.currency);
      setAmountToPay(planAmount);
    } else {
      setCurrency(freePlan.currency);
    }
  };

  const calculateAnnualTotalDiscount = () => {
    if (isPremiumPlan && !isMonth) {
      const baseDiscount = 30;
      if (discount) {
        // let annualListPrice = (monthlyPlan.amount * 12);
        // setAnnualTotalDiscount(Math.round((1 - (amountToPay / annualListPrice)) * 100));
        setAnnualTotalDiscount(baseDiscount + discount.amount);
      } else {
        setAnnualTotalDiscount(baseDiscount);
      }
    } else {
      setAnnualTotalDiscount(0);
    }
  }

  const handleIos = () => {
    utils.toggleScrollBar(!openPopUpIos);
    setOpenPopUpIos(false);
  }

  const handleDiscounts = () => {
    if (!isPremiumPlan || isMonth) return;
    const baseDiscount = 30;
    if(discount){
      const annualizedMonthlyPrice = monthlyPlan.amount * 12;
      const couponDiscountOfAnual = anualPlan.amount * (discount.amount / 100);
      const percentageFromMonthlyAnualized = (couponDiscountOfAnual / (annualizedMonthlyPrice)) * 100;
      const priceWithDiscount = (anualPlan.amount - couponDiscountOfAnual);
      setAnnualTotalDiscount(Math.ceil(percentageFromMonthlyAnualized + baseDiscount));
      setAmountToPay(Math.ceil(priceWithDiscount));

    }
  } 

  const checkPlanDiscount = () => {
    let isAvailable;
    if (discount !== null && discount !== undefined && monthlyPlan !== undefined) {
      const availablePlans = discount.available_for.split(",");
      availablePlans.forEach((id) => {
        if (id === monthlyPlan.id || id === anualPlan.id) {
          isAvailable = true;
        }
      });
    }
    if (isAvailable) {
      return true;
    }
  };

  const getButtonText = () => {
    if (blockedPlan) {
      return t("premium_landing_view_current_plan");
    } else if (user.currentPlanId) {
      return isPremiumPlan ? (user.canUseFreeTrial && !isHundredPercentOff ? t("premium_landing_view_free_trial") : t("premium_landing_view_get_premium")) : t("premium_landing_view_update_plan");
    } else {
      return t("premium_landing_view_join_now");
    }
  };

  return (
    <Card isAppleDevice={isAppleDevice} isPremiumPlan={isPremiumPlan}>
      {discount !== null && discount !== undefined && checkPlanDiscount() && (
        <>
          <DiscountHeader>
            <Trans i18nKey={"premium_card_discount_header"} values={{ percentage: isMonth ? discount.amount : (isHundredPercentOff ? 100 : annualTotalDiscount) }} />
            <span>{t("premium_card_discount_applied")}</span>
          </DiscountHeader>
        </>
      )}

      {/* Plan Name */}
      <h1>
        {isPremiumPlan ? (
          <span>Premium</span>
        ) : (
          <span>
            Free
          </span>
        )}{" "}
        {isPremiumPlan && <MostPopular>{t("premium_landing_view_most_popular")}</MostPopular>}
      </h1>

      {/* Separator */}
      <hr style={{ color: "#bbb" }} />

      {/* Plan Features */}
      <FeatureContainer isPremium={false}>
        <FontAwesomeIcon icon={faCheck} />
        <p><Trans i18nKey={"plan_card_feature_cameras_comparison_" + (isPremiumPlan ? "premium" : "free")} component={{ bold: <strong /> }} values={{camCount:  camCount[(isPremiumPlan ? "premium" : "free")]}} /></p>
      </FeatureContainer>
      {
        planFeatures.map(({ premium, description }, index) => {
          const isPremium = isPremiumPlan || !premium;
          const planType = isPremiumPlan ? "premium" : "free";
          let transKey = (description + "_" + planType);
          return (
            <FeatureContainer isPremium={premium && !isPremiumPlan}>
              <FontAwesomeIcon icon={isPremium ? faCheck : faXmark} />
              <p><Trans i18nKey={transKey} component={{ bold: <strong /> }} /></p>
            </FeatureContainer>
          )
        })
      }

      {/* Separator */}
      <hr style={{ color: "#bbb" }} />

      {/* Pricing */}
      {(isPremiumPlan && !isMonth ? (
        <React.Fragment>
          <h3>
            <span className={discount !== null ? "fadeoutPricing" : null}>{currency} {utils.getFormattedAmount(anualPlan.amount / 12)}</span>
            {discount !== null && (
              <span className={discount !== null ? "pricingWithDiscount" : null}>{currency} {isHundredPercentOff ? 0 : utils.getFormattedAmount(amountToPay / 12)}</span>
            )}
            <TypePrice> / {t("premium_landing_view_per_month")}</TypePrice>
          </h3>
          <AnualLabelPrice>
            <Trans i18nKey="premiumCardComponent_billedAnnual" />&nbsp;
            <strong className={discount !== null ? "fadeoutDiscount" : null}>30%</strong>
            <strong style={{ display: discount !== null ? "inline-flex" : "none" }}>{isHundredPercentOff ? 100 : utils.getFormattedAmount(annualTotalDiscount)}%</strong>
            <strong>&nbsp;OFF</strong>
          </AnualLabelPrice>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3>
            {currency} {utils.getFormattedAmount(amountToPay / 12)} <TypePrice> / {t("premium_landing_view_per_month")}</TypePrice>
          </h3>
          {<AnualLabelPrice className={`${!isPremiumPlan && 'unusable'}`} >{t("premium_landing_view_recurring_billing_month")}</AnualLabelPrice>}
        </React.Fragment>
      ))}

      {/* Payment Schedule Toggle (Annual/Monthly) */}
      
      <div className={`${!isPremiumPlan && 'unusable'} switch-wrap d-flex justify-content-center`} id="BillingType">
        <div className="switch-plans justify-content-center">
          <span>{t("_ANUALLY")}</span>
          <div className="primary-switch justify-content-center">
            <label className="switch">
              <input type="checkbox" id="freqPayment" onChange={() => handlePlan()} checked={isMonth} />
              <span className="slider-sub round"></span>
            </label>
          </div>
          <span>{t("_MONTHLY")}</span>
        </div>
      </div>
      

      {/* Popup IOS */}
      {openPopUpIos && (
        <PopupOverlay>
          <div className="ios-premium-poup-container">
            <IphoneDeviceAlert>
              {t("premium_card_component_apple_device_detected")}
            </IphoneDeviceAlert>    
            <button onClick={() => handleIos()}>{t("ad_popup_continue")}</button>
          </div>
        </PopupOverlay>
      )}

      {/* Conversion Button */}

      <button disabled={blockedPlan} onClick={() => selectedPlan()}>{getButtonText()}</button>

    </Card>
  );
};

const FeatureContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  color: ${(props) => props.isPremium ? '#bbb' : '#203657'};
  svg{
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }

  p{
    margin: 0 !important;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fit-content !important;
  }

`

const DiscountHeader = styled.div`
  font-size: 15px;
  width: 100%;
  height: 28px;
  background: #80c477;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px 16px 0 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  span {
    font-weight: 600;
    margin-left: 5px;
  }
`;

const AnualLabelPrice = styled.p`
  font-size: 15px !important;
  margin-top: -8px !important;
  margin-bottom: 0px !important;
  color: #203657;
  height: auto !important;
`;

const IphoneDeviceAlert = styled.p`
  color: #203656 !important;
  font-size: 15px !important;
  height: auto !important;
  font-weight: 700;
  margin: 0 !important;
`;

const Card = styled.div`
  padding: 20px;
  margin: 0px 10px;
  border: 1px solid #e4e7ed;
  box-shadow: ${(props) => props.isPremiumPlan ? "0 5px 15px rgb(255 165 60)" : "0 5px 15px rgb(0 0 0 / 10%)"};
  border-radius: 16px;
  width: 300px;
  min-height: ${(props) => props.isAppleDevice ? "335px" : "385px"};
  position: relative;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }

  button {
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    background-color: #2b5283;
    color: #fff;
    font-weight: 800;
    font-size: 0.7em;
    margin: 10px 0 10px 0;
    margin-right: 20px;
    width: 100%;

    background-color: #ffa53c;
    color: #000;
    border-radius: 5px;


    @media (max-width: 1000px) {
      position: initial;
      bottom: 0px;
      margin-right: 20px;
      width: 100%;
    }
  }
  button:disabled,
  button[disabled] {
    box-shadow: none;
    background-color: rgb(255 165 60 / 30%) !important;
    color: rgb(0 0 0 / 50%) !important;
  }

  h1 {
    font-size: 2em;
    font-weight: 800;
    margin: 10px 0px;
  }

  h3 {
    font-size: 1.2em;
    font-weight: 700;
    margin: 12px 0px;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.8em;
    margin-bottom: 25px;
    height: 55px;
  }

  .not-apply-free-trial {
    color: #99a094 !important;
    text-decoration: line-through !important;
  }

  .anual-description {
    position: absolute;
    font-size: 0.8em;
    margin-top: -22px;
    font-weight: 500;
  }

  .margin-anually {
    margin-bottom: 40px;
  }

  .unusable{
    opacity: 0;
    pointer-events: none;
  }

  .fadeoutPricing {
    color: #bbb;
    font-size: 17px;
    margin-right: 5px;
    text-decoration: line-through;
  }

  .pricingWithDiscount {
    display: inline-flex;
  }

  .fadeoutDiscount {
    color: #bbb;
    margin-right: 5px;
    text-decoration: line-through;
  }
`;

const MostPopular = styled.span`
  background-color: #000;
  font-size: 8px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
  float: right;
  margin-top: 3px;
`;

const TypePrice = styled.span`
  font-size: 11px;
  font-weight: 600;
  margin-left: 0px;
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  isAppleDevice: state.isAppleDevice,
});

export default connect(mapStateToProps)(React.memo(AddComponent));
